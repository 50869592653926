import { lighten, modularScale, rem } from 'polished'

export type ThemeType = typeof theme & {
  colours: {
    new: { [key: string]: string }
  }
}

const theme = {
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)',
  clientBoxShadow: '0px 4px 10px rgba(28, 36, 82, 0.1)',
  newClientBoxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)',
  clientPortalFormBoxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.04), 0px 20px 25px 0px rgba(0, 0, 0, 0.1)',
  colours: {
    // New colours
    base: '#444444',
    baseMid: '#71747E',
    baseLight: '#D7DAE0',
    baseExtraLight: '#F5F6FA',
    black: '#1F2937',
    basicBlack: '#000000',

    primary: '#0E4EFB',
    hover: '#507FFF',
    pressed: '#0035C5',
    danger: '#CC0700',
    success: '#007B46',
    warning: '#B94A00',

    background: '#F9F9FA',
    foreground: '#FFFFFF',
    lightGrey: '#F2F2F2',

    // vv Old colours - please deprecate vv
    primaryPaleColor: '#F2F5FF',
    primaryColorDisabled: lighten(0.35, '#0E4EFB'),

    successColor: '#0DA060',
    backgroundOffWhite: '#FAF5F0',
    backgroundLightBlue: '#F5F7FA',
    // ^^ Old colours - please deprecate ^^

    primary10: '#E6EDFF',

    lightOrange: '#FFE0B2',
    orange: '#FFCAB4',
    babyPink: '#FFCDD2',
    pink: '#FFB5CF',
    lightPurple: '#F7D8F3',
    purple: '#C4B2F7',
    oryPurple: '#EEF2FF',
    lightRed: '#FFD5D5',
    lightBlue: '#BBE4FB',
    periwinkle: '#C2D3FF',
    skyBlue: '#B2EDF2',
    aquaBlue: '#C5F3F3',
    lightGreen: '#C5F0CC',
    green: '#ABE7AD',
    darkGreen: '#1E762E',
    grey: '#CED9E0',
    lightYellow: '#FFEBC4',
    darkYellow: '#C43A00',
    noteTextArea: '#F9F9FA',
    ghostWhite: '#F0F1F7',
    pattensBlue: '#CEE5FF',
    cobalt: '#0053B3',
    wittyGreen: '#E2F0C5',
    moss: '#55721D',
    quartz: '#DEDBFF',
    violet: '#534ABA',
    transparent: 'transparent',
    rose: '#FFE4E6',
    roseText: '#BE123C',
    grey800: '#27272A',
    new: {
      amber: '#B94A00',
      amber2: '#92400E',
      amber3: '#FBBF24',
      gray200: '#E5E7EB',
      baseExtraLight: '#f3f4f6',
      baseLightColour: '#d1d5db',
      baseMidColour: '#6b7280',
      baseColour: '#374151',
      cyan: '#22D3EE',
      emerald: '#34D399',
      fuchsia: '#E879F9',
      primaryColour: '#4338ca',
      indigo1: '#E0E7FF',
      indigo2: '#C7D2FE',
      indigo3: '#312E81',
      indigo4: '#818CF8',
      lime: '#A3E635',
      lime1: '#D9F99D',
      lime2: '#4D7C0F',
      peach2: '#FF8400',
      peach3: '#FFDAD980',
      blue1: '#0E4EFB',
      blue2: '#507FFF',
      blue3: '#0035C5',
      brown1: '#E8D9CF',
      brown2: '#795A49',
      green: '#007B46',
      green2: '#22C55E',
      grey1: '#F5F6FA',
      grey2: '#71747E',
      grey3: '#D7DAE0',
      grey4: '#444444',
      grey5: '#F9FAFB',
      grey6: '#4B5563',
      mint1: '#C5F0CC',
      mint2: '#1E762E',
      navy1: '#CEE5FF',
      navy2: '#0053B3',
      olive1: '#E2F0C5',
      olive2: '#55721D',
      orange1: '#FFE4D2',
      orange2: '#B34100',
      orange3: '#FB923C',
      pink1: '#FFE2F9',
      pink2: '#AD008B',
      pink3: '#F472B6',
      pink4: '#FEF2F2',
      purple1: '#F0D8FF',
      purple2: '#713596',
      purple3: '#C084FC',
      red1: '#FFDAD9',
      red2: '#CC0700',
      red3: '#991B1B',
      red4: '#F87171',
      rose: '#FB7185',
      turquoise1: '#C5F3F3',
      turquoise2: '#177272',
      violet1: '#DEDBFF',
      violet2: '#534ABA',
      yellow1: '#FFEBC3',
      yellow2: '#C33A00',
      yellow3: '#FDE68A',
      yellow4: '#FEF9C3',
      transparent: 'transparent',
      foreground: '#F3F4F6',
      white: '#FFF',
    },

    // client portal branding colours
    // DO NOT CHANGE UNLESS INSTRUCTED
    // these values are saved as a field on the organisation
    portalOptions: {
      gray: '#374151',
      blue: '#1D4ED8',
      red: '#B91C1C',
      orange: '#C2410C',
      green: '#047857',
      lightGreen: '#F0FDF4',
    },
  },

  opacity: {
    op20: 0.2,
    op40: 0.4,
    op50: 0.5,
    op60: 0.6,
    op80: 0.8,
    op90: 0.9,
    op95: 0.95,
  },

  typography: {
    fontSizes: {
      size10: '10px',
      size12: '12px',
      size14: '14px',
      size16: '16px',
      size20: '20px',
      size22: '22px',
      size24: '24px',
      size26: '26px',
      size32: '32px',
      size42: '42px',
    },

    lineHeight: '1.6',
    lineHeightSmall: '1.4',

    fontFamilyDisplay: 'Roboto, serif',
    fontFamily: 'Roboto, sans-serif',
    fontFamilyGrotesk: 'Space Grotesk, sans-serif',
    fontWeight: 400,
    fontWeight500: 500,
    fontWeightBold: 700,
  },

  display: {
    spacingIncrement: 4,
    inputBorderWidth: '1px',
    inputHeightShort: '32px',
    inputHeightMedium: '40px',
    inputHeight: '48px',
    borderRadius: '6px',
    borderRadiusMedium: '12px',
    borderRadiusLarge: '16px',

    // vv Please deprecate vv
    smallSpacer: '8px',
    mediumSpacer: '16px',
    largeSpacer: '32px',
    ms0: modularScale(-2),
    ms1: modularScale(1),
    ms2: modularScale(2),
    ms3: modularScale(3),
    ms4: modularScale(4),
    ms5: modularScale(5),
    // ^^ Please deprecate ^^

    navHeightSmall: 50,
    navHeightMedium: 112,
    navHeightLarge: 156,
    navTextWidth: 120,
    navIconWidth: 50,

    sideNavWidth: 239,

    // Local stacking context Z Indices
    overflowZIndex: 1,

    // Global stacking context Z Indices
    // these are in orders of 1000, since leaflet (library used for maps in property info section)
    // uses a range of z-indices and 1001 was the lowest "safe" value found to cover all elements in leaflet
    // Since the introduction of MUI we started using ` indices from that library, where the sidenav's z index is 1200
    missingFieldTooltipZIndex: 1202,
    subnavZIndex: 1203,
    navZIndex: 1204,
    sideSheetBackdropZIndex: 1205,
    sideSheetZIndex: 1206,
    fullPageModalZIndex: 1207,
    modalBackdropZIndex: 1208,
    modalZIndex: 1209,
    alertZIndex: 1210,

    popOverWidthMin: 180,
    popOverWidthMax: 240,
    downloadDialogFieldWidthMax: 269.2,
    modalHeadingHeight: 96,
    modalPadding: '3rem',
    modalPaddingSmaller: '2rem',
    modalPaddingSmallest: '1rem',
    formMediumWidth: 480,
    drawerNavWidth: 320,
    formCardMinHeight: 562,
    loginPanelWidthMin: 260,
  },

  spacers: {
    size0: rem('0px'),
    size4: rem('4px'),
    size8: rem('8px'),
    size12: rem('12px'),
    size16: rem('16px'),
    size20: rem('20px'),
    size22: rem('22px'),
    size24: rem('24px'),
    size32: rem('32px'),
    size40: rem('40px'),
    size48: rem('48px'),
    size64: rem('64px'),
    size80: rem('80px'),
    size128: rem('128px'),
  },

  wrappers: {
    a4Width: '8.27in',
    sidebar: '240px',
    small: '320px',
    medium: '480px',
    large: '800px',
    Large: '960px',
    extraLarge: '1280px',
    extraExtraLarge: '1440px',
    fullScreen: '100vh',
    mediumBreakpoint: 768, // no px as we do math on this in media queries,
    largeBreakpoint: 1200, // no px as we do math on this in media queries,
    pageContent: '1056px',
  },

  clientWrapper: {
    small: '374px',
    large: '512px',
    extraLarge: '1200px',
    extraExtraLarge: '1440px',
    navigationPanelWidth: '300px',
    requirementsWrapper: '640px',
    documentPreviewHeight: '600px',
    schedulerWrapper: '992px',
    mobileBreakpoint: '1023px',
    chatListWidth: '348px',
    messageBubbleWidth: '560px',
  },

  companyLogo: {
    width: 160,
    height: 80,
  },
  userPhoto: {
    width: 320,
    height: 320,
  },
}

export default theme
