import DataLoader from 'dataloader'

import configs from '@acre/config'

import {
  DocumentVerificationQueryParams,
  GetDocumentDetailsQueryVariables,
  GetDocumentSummariesQueryVariables,
  Maybe,
  MiDocumentSummaryFiltersInput,
  MiDocumentSummaryResponse,
  MiDocumentTotalsFiltersInput,
  MiDocumentTotalsResponse,
} from '../generated/resolvers'
import { clientLoader } from '../loaders/client'
import { mortgageLoader } from '../loaders/mortgage'
import request from '../requesters/default'
import {
  CdmAddCaseDocumentRequest,
  CdmAddCaseDocumentResponse,
  CdmCreateDocumentVerificationRequest,
  CdmCreateDocumentVerificationResponse,
  CdmDeleteCaseDocumentResponse,
  CdmDocument,
  CdmGetDocumentRequirementsResponse,
  CdmGetDocumentResponse,
  CdmGetDocumentSummariesResponse,
  CdmGetDocumentVerificationResponse,
  CdmRenderTemplateRequest,
  CdmRenderTemplateResponse,
  CdmReplaceDocumentResponse,
  CdmTestRenderTemplateRequest,
  CdmTestRenderTemplateResponse,
  CdmUpdateDocumentResponse,
  CdmUploadDocumentRequest,
  CdmUploadDocumentResponse,
} from '../service/luther/model'
import { CaseLoader } from './case'

const { API1_URL } = configs
// before using fetch calls, use laoders in case data already exist in cache
export const DocumentSummariesLoader = new DataLoader((params: GetDocumentSummariesQueryVariables[]) =>
  Promise.all(params.map((param) => getDocumentSummariesBatchFn(param))),
)

export const DocumentDetailsLoader = new DataLoader(
  (
    payloads: {
      documentId: string
      params?: GetDocumentDetailsQueryVariables['params']
    }[],
  ) => Promise.all(payloads.map((payload) => getDocumentBatchFn(payload.documentId, payload.params))),
)

// GetDocument
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/GetDocument
const getDocumentBatchFn = async (documentId: string, params?: GetDocumentDetailsQueryVariables['params']) => {
  const response = await request.get<CdmGetDocumentResponse>(`/document/${documentId}`, {
    params,
  })
  return response?.data
}

export const getDocument = async (documentId: string, params?: GetDocumentDetailsQueryVariables['params']) => {
  return DocumentDetailsLoader.load({ documentId, params })
}

// GetDocumentSummaries
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/GetDocumentSummaries
const getDocumentSummariesBatchFn = async (params?: GetDocumentSummariesQueryVariables) => {
  const response = await request.get<CdmGetDocumentSummariesResponse>('/document', { params })
  return response?.data
}

export const getDocumentSummaries = async (params?: GetDocumentSummariesQueryVariables) => {
  return await DocumentSummariesLoader.load(params!)
}

// GetMiDocumentSummaries
export const getMiDocumentSummaries = async (
  entitity: 'case' | 'client',
  id: string,
  filters?: Maybe<MiDocumentSummaryFiltersInput>,
) => {
  const response = await request.get<MiDocumentSummaryResponse>(`/mi/${entitity}/${id}/documents`, {
    baseURL: `${API1_URL}/acre`,
    params: filters,
  })

  return response?.data
}

export const getMiDocumentTotals = async (
  entitity: 'case' | 'client',
  id: string,
  filters?: Maybe<MiDocumentTotalsFiltersInput>,
) => {
  const response = await request.get<MiDocumentTotalsResponse>(`/mi/${entitity}/${id}/documents/totals`, {
    baseURL: `${API1_URL}/acre`,
    params: filters,
  })

  return response?.data?.Totals
}

// UploadDocument
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/UploadDocument
export const uploadDocument = async (body: CdmUploadDocumentRequest) => {
  const response = await request.post<CdmUploadDocumentResponse>('/document', body)
  // If mortgage id is present, clear the cached mortgages from dataloader,
  // otherwise upon mortgage refetch in GQL, eor_documents will not post latest results
  if (body.mortgage_id) {
    mortgageLoader.clearAll()
  }
  return response?.data
}

// RenderTemplate
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/RenderTemplate
export const renderTemplate = async (body: CdmRenderTemplateRequest) => {
  const response = await request.post<CdmRenderTemplateResponse>('/document/render_template', body)
  CaseLoader.clearAll()
  clientLoader.clearAll()
  return response?.data
}

export const renderTestTemplate = async (body: CdmTestRenderTemplateRequest) => {
  const response = await request.post<CdmTestRenderTemplateResponse>('/document/render_template/test', body)

  return response?.data
}

// UpdateDocument
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/UpdateDocument
export const updateDocument = async (documentId: string, body: CdmDocument) => {
  const response = await request.patch<CdmUpdateDocumentResponse>(`/document/${documentId}`, body)
  return response?.data
}

// ReplaceDocument
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/ReplaceDocument
export const replaceDocument = async (documentId: string, body: CdmUploadDocumentRequest) => {
  const response = await request.put<CdmReplaceDocumentResponse>(`/document/${documentId}`, body)
  return response?.data
}

// GetDocumentVerification
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/GetDocumentVerification
export const getDocumentVerification = async (documentId: string, params?: Maybe<DocumentVerificationQueryParams>) => {
  const response = await request.get<CdmGetDocumentVerificationResponse>(`/document/${documentId}/verification`, {
    params,
  })
  return response?.data
}

// GetDocumentRequirements
// https://crm.acreplatforms.co.uk/api_documentation/redoc.html#operation/GetDocumentRequirements
export const getDocumentRequirements = async (filterOrganisationIds: string[], requirementDetails: Maybe<boolean>) => {
  const response = await request.get<CdmGetDocumentRequirementsResponse>(
    `/requirement?filter_organisation_ids=${filterOrganisationIds}&requirement_details=${requirementDetails ?? true}`,
  )
  return response?.data
}

// CreateDocumentVerification
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/CreateDocumentVerification
export const createDocumentVerification = async (documentId: string, body: CdmCreateDocumentVerificationRequest) => {
  const response = await request.post<CdmCreateDocumentVerificationResponse>(
    `/document/${documentId}/verification`,
    body,
  )
  return response?.data
}

// AddCaseDocument
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/AddCaseDocument
export const addCaseDocument = async (documentId: string, body: CdmAddCaseDocumentRequest) => {
  const response = await request.post<CdmAddCaseDocumentResponse>(`/document/${documentId}/case`, body)
  return response?.data
}

// DeleteCaseDocument
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/DeleteCaseDocument
export const deleteCaseDocument = async (documentId: string, caseId: string) => {
  const response = await request.delete<CdmDeleteCaseDocumentResponse>(`/document/${documentId}/case/${caseId}`)
  return response?.data
}
